
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiTwitter, FiMail } from 'react-icons/fi';
import './writing.css';


const articles = {
  growth: [
    { title: "The 180 Day Challenge", emoji: "🤔", link: "thechallenge" },
    { title: "Daily Wins Stack Up", emoji: "💯", link: "dailywins" },
    {title: "Create Your Own Beliefs", emoji: "🌎", link: "createbeliefs"},
    {title: "Information Overload", emoji: "ℹ️ ", link: "informationoverload"},




  ],

  connect: [
    { title: "Building a Community: Summer 2023", emoji: "📚", link: "buildcommunity" },
    {title: "Evaluating Friendships", emoji: "🤝", link: "evaluatingfriendships"},

  ],
  reflection: [
    { title: "What makes a lousy day (for me)", emoji: "📉", link: "lousyday" },
    { title: "Naval", emoji: "🌱", link: "naval" },
    { title: "Meaningwave", emoji: "🎵", link: "meaningwave" },
    { title: "Blessings", emoji: "🤍", link: "blessings" },
    { title: "Social Media", emoji: "📱", link: "socialmedia" },


    // { title: "Article 1", emoji: "📚", link: "Article9" },
    // { title: "Coming Soon", emoji: "🎉", link: "Article10" },
  ]
};

function Writing() {
  const [tab, setTab] = useState("growth");
  const location = useLocation();

  return (
    <div className="Apper">
      <div className="tabs">
        <button className={tab === "growth" ? "active" : ""} onClick={() => setTab("growth")}>Personal Growth</button>
        <button className={tab === "connect" ? "active" : ""} onClick={() => setTab("connect")}>Strong Community</button>
        <button className={tab === "reflection" ? "active" : ""} onClick={() => setTab("reflection")}>Life Reflections</button>

      </div>
      <div className="articles">
        {articles[tab].map(article => (
          <Link to={`${location.pathname}/${article.link}`} className="article" key={article.title}>
            {article.emoji} <strong> {article.title}</strong>
          </Link>
        ))}
      </div>

      <div className="contact-icons">
        <a href="https://twitter.com/Aryan_Gandhi101" target="_blank" rel="noreferrer">
          <FiTwitter size={32} />
        </a>
        <a href="mailto:aryang6@illinois.edu?subject=Saying Hello!&body=Sup! my name is ">
          <FiMail size={32} />
        </a>
      </div>
    </div>
  );
}

export default Writing;

