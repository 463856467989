import React, { useState, useEffect } from "react";

import "./Experience.css"
import Typewriter from 'typewriter-effect';
import catlearn from './catfinal.svg';
import rsm from './RSM.svg'


function Experience() {
  const [text, setText] = useState("");
  const [typingDone, setTypingDone] = useState(false);

  const fullText = "My Experience";

  useEffect(() => {
    if (text !== fullText && !typingDone) {
      setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 100);  // Speed of typing effect in ms
    }

    if (text === fullText && !typingDone) {
      setTypingDone(true);
    }
  }, [text, fullText, typingDone]);
  return (
    <div className="experience-container">
      <div className="page-title">
        {text}<span className="typing-cursor">|</span>
      </div>
      <div className="experience-box">
        <h2 className="experience-title">Caterpillar ~ 15 Months</h2>
        <img src={catlearn} alt="catlearn" className="experience-image" />
        <p className="experience-description">
        At Caterpillar, I undertook an extensive digital transformation, focusing on cloud and software engineering. My contributions included automating data pipelines, enhancing serverless architectures for improved latency, and fortifying security measures to safeguard sensitive data on AWS. I also created a full-stack streaming application and leveraged machine learning for data processing. Through these initiatives, I played a crucial role in optimizing real-time data analysis and event processing.
        </p>
   
      </div>
      <div className="experience-box">
        <h2 className="experience-title">RSM ~ 6 Months</h2>
        <img src={rsm} alt="rsmlearn" className="experience-image" />
        <p className="experience-description">
        At RSM, I worked as part of a dynamic team exploring novel methods for tax compliance. I contributed significantly by developing a real-time Tax Regulation Compliance Checker using Django and MongoDB, with an integrated NLP system. This platform was revolutionary, as it effectively parsed and simplified complex tax regulations, making compliance more straightforward and accessible.
        </p>

      </div>
      {/* <div className="experience-box">
        <h2 className="experience-title">State of Illinois Government</h2>
        <img className="experience-image" src={require("./logo192.png")} alt="State of Illinois Government project" />
        <p className="experience-description">
          This is a placeholder description for the project I worked on at State of Illinois Government. I will update this with real project details later.
        </p>
        <a className="experience-link" href="https://www2.illinois.gov">Learn more</a>
      </div> */}
    </div>
  
  );
}

export default Experience;