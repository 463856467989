import React from 'react';
import './Article.css';

function Article10() {
    return (
        <div className="article-container">
        <h1 className="article-title">📱Social Media</h1>
        <div className="article-content">
        
        <p>Wanted to take a look at how I use social media, and the goals moving forward with it. I want to figure out how to make it work for me. </p>
        
        <p> Currently use too much Youtube and Twitter. I’m a big fan of movie summaries so can watch 3 of those per day ~ 45 minutes. Twitter I enjoy, however sometimes when bored will end up doom scrolling on the app for another 45 minutes. This all adds up to over ~1.5 hours of wasted time per day, probable more, considering I feel worse after and it takes longer to get my attention to the task at hand.</p>
        
        <p> Instead, I’ve decided to create a social media plan. This way, it’s public and there are no excuses to not using it as I have mentioned. I’ve been wanting to start creating more on social media, but was not sure what to do.  </p>
        
        
        <p> I’m already working on leverage through code, I should do my best to add to that through social media. </p>
        
        <p> Social media based on personal value (ranked): Twitter, AirChat, Youtube, LinkedIn, Threads, Instagram, Snapchat, Facebook. </p>
        
         <ul>
                       <li> Twitter → Already posting essays on here. Going to start writing tweets on tech that interests me. A lot of the concepts I know are fragmented, and through an interview with Elon I saw his method of learning is a semantic tree. Committing to writing 9 threads this way (before school start August 16th), Been wanting to do some deep dives into databases (how they work), multi-threading (how to use it), vector databases (a lot of people building efficient versions). Writing about these in a semantic tree of knowledge way should allow me to understand the whole topic better. Then of course, also post my general thoughts.
        
        1 rule: Only go on Twitter when you are going to post your content. 
        
        </li> 
        
        <li> AirChat → This is the only social media that I feel better after using. Getting to talk and listen to people about specific topics is great. There is no trolling, no hate, no negativity, just honest conversations about a topic. If someone is wrong about something, no one criticizes them and makes it a big deal. Everyone I have interacted with on this app has come here to learn, which is absolutely crazy to me. Never have I had an experience like this on any other app, extremely excited for when it launches. 
        
        Just focused on genuine conversation on this app. Not worried about growing or even meeting as many people as possible, just going with the flow on this one. </li> 
        
        <li> Youtube → Not going to create content here (yet), but definitely an awesome educational platform. Have learned so much from here, would be a disservice to not put it here. </li>
        
        <li> LinkedIn → Not interested in becoming a LinkedIn influencer or posting on there. I’ve rarely found any value in LinkedIn posts. However, it is a great app for career related networking, so for that aspect it is second. Not much to do here, besides reach out to people who I am interested in talking too. 
        
        Re-visiting this, could start posting content that provides value to others on here. If I feel a lot of the content does not provide value, then I could figure out a way to provide it. Will think more on this. </li>
        
        <li> Threads → I use Twitter because of the types of people on there and the type of content. Threads is a milder version of that, along with it has most of my high school and college friends. I use Twitter to branch out and meet others, not have Twitter like conversations with these individuals. Not a big fan of threads, have posted one thread thus far, but will probably not use platform much. </li> 
        
        <li> Snapchat → Not a huge fan. Rarely use (~once per week). Can be useful in some instances.</li>
        
        <li> Instagram → Not a big fan, watch some reels from time to time, but other than that don’t find much use. </li>
        
        <li> Facebook → Haven’t been on there in months. </li>
          </ul>
            </div>
        </div>
    );
}

export default Article10;
