import React from 'react';
import './Article.css';
import navalmanac from './navalmanac.PNG'



function Article11() { 
    return (
        <div className="article-container">
        <h1 className="article-title">ℹ️Information Overload</h1>
        <div className="article-content">

        <p> “<b>If </b> more <b>information</b> was the <b>answer</b>, then we'd all be billionaires with perfect abs.” - Derek Sivers. </p>

<p> I’ve been consuming a lot of different information lately. I listen to a few podcasts (Danny Miranda, Chris Williamson, Impact Theory, etc), read blogs and books. However, I’ve noticed I tend to merely consume as opposed to acting on the information I learn. </p>

<p> In elementary and middle school I was an avid reader, read at least an hour a day of mostly fiction books. Through high school I dropped the habit, but in 12th grade after reading the Navalmanac, slowly re-introduced it into my life. Started reading a lot of non-fiction, and bounced between books until I found interesting ones. </p>

<p> Sadly, over the last year I’ve stopped reading and I decided two weeks ago to get back into it. But this time there is a twist: I’m drastically reducing the number of books I read, and significantly increasing the time and quality I spend with each book. Further, I want to write concrete steps I will take after reading a book in order to focus on <b>more</b> action and <b>less</b> information overload. </p>

<p> I’m going to outline a plan that I feel is the best to move past this lack of actions: </p>

<ol>
    <li>Choosing a Book and Delving Deep: I'm going to select a book and write notes on each page as I read, followed by summarizing the book. Here's why this approach matters to me:
        <ol type="a">
            <li>Writing about the content helps reinforce my memory, and I can take the time to fully understand what I'm reading.</li>
            <li>I aim to understand the writing style of the author, as part of my aspiration to improve my own writing.</li>
            <li>The first book on my list is "The Navalmanac." It's packed with insights that I'm eager to explore.</li>
            <li>I'll document what I learn through a Twitter thread, reflecting on the experience and its value.</li>
            <li>Ex. <img className = "image-container" src={navalmanac} alt="Airchat"></img></li>
        </ol>
    </li>
    <li>Limiting Podcasts to 2-3 Episodes a Week: This is about applying what I learn, rather than just accumulating more information.
        <ol type="a">
            <li>With so many podcasts offering differing viewpoints, I'll concentrate on guests whose track records resonate with me, applying their advice where it makes sense.</li>
            <li>The time I save will be used for reflection and forward-thinking, allowing for clarity and focus on the future.</li>
            <li>I'm also planning to explore meditation, aiming to reduce information overload and cultivate a clear head.</li>
        </ol>
    </li>
</ol>


<p> Thanks to Nikita Saripella for reading drafts. </p>


        

                  
                </div>
            </div>
    
    );
}

export default Article11;
