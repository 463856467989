import React from 'react';
import './Article.css';
import { FiTwitter, FiMail } from 'react-icons/fi';

function Article7() {
    return (
        <div className="article-container">
            <h1 className="article-title">📉What makes a lousy day (for me)</h1>
            <div className="article-content">

            <p>Top 8 things that I know negatively affect either my physical or mental health each day.</p>
    <ol>
        <li><strong>Social media right in the morning.</strong> This is arguably the one that affects my mental health the most. I am able to focus well in the morning, as my mind is empty. However, social media tends to ruin that focus and usually has something negative on it.
            <ul>
                <li>Put phone on black and white (except when google maps needed), remove social media and run SelfControl for Mac. Essentially make it inaccessible.</li>
            </ul>
        </li>
        <li><strong>Sugary food in the morning.</strong>  I tend to fast until around noon, but there have been a few days when I wake up and eat cereal and milk or some other sweet treat. Each time, it doesn't feel too good.
            <ul>
                <li>Just don't buy any sugary foods.</li>
            </ul>
        </li>
        <li><strong>Sleeping late.</strong> Today is a good example, somehow woke up at 1:00 PM. I have bursts where I follow a proper schedule and then there will be like 1 or 2 days in a week where the schedule gets completely messed up. Going to make sure for the next 30 days the schedule stays relatively the same via wake and sleep time.
            <ul>
                <li>Well obviously go to bed early. And follow a proper schedule. Also no caffeine unless you lift, or it is before noon.</li>
            </ul>
        </li>
        <li><strong>Junk food.</strong> Same idea as sleeping late. I follow a pretty solid diet for most days. But sometimes I get junk food and my self-control with it is pretty much non-existent. Yesterday got a big bag of these pizza veggie puffs. Over 2,000 calories bag, and now it is 75% empty, 1.5 days later. I don't really miss it if it isn't there so just need to stop purchasing. Also, the junk food isn't bad, it's about how I feel after, pretty lethargic.
            <ul>
                <li>Same thing as sugary breakfast, just don't buy it and problem solved.</li>
            </ul>
        </li>
        <li><strong>Not standing up for something I believe in.</strong> This has to do with thinking before I speak a lot, and it just leads to a recursive feedback loop where I keep my mouth shut. I should be more reactive after thinking about a decision, I mean what’s the worst that can happen? Maybe I'm wrong in a situation? But then at least I know I'm wrong instead of not saying anything. Or vice versa, better to call out lousy behavior/thoughts/actions and see what happens then waiting for it to stop.
            <ul>
                <li>Once you’ve debated a decision, maintain full confidence in acting on it.</li>
            </ul>
        </li>
        <li><strong>Lazy.</strong> There are some days when everything actually is going to plan, but I am just lazy. These days are the fewest, however, because waking up early, eating healthy, etc help promote proper working habits for me, at least. (The reason I say for me is some of the best programmers or engineers I know, I see them chugging red bulls and eating pizza rolls on a daily basis. In my eyes, that's pretty amazing that they can do that and maintain productivity, but it's just not me).
            <ul>
                <li>This one is just a mindset block. Don't be a coward and hide behind how you feel. Get up and do the work.</li>
            </ul>
        </li>
        <li><strong>Lack of night routine.</strong> This is the one I am worst at, I tend to be on my laptop or be on social media until I sleep. It's gotten better recently but I need to enact a 1-hour routine prior to bed. But at the same time, I've found thinking about a certain topic prior to sleep, enables me to think about it as I sleep. A slept-on superpower. So then think about a topic prior to bed would be best.
            <ul>
                <li>Some combination of journaling, reading and thinking of a topic to think about while sleeping would be ideal.</li>
            </ul>
        </li>
        <li><strong>Not working out.</strong> This is not a daily issue, but ideally, 2-3 workouts per week have me feeling my best. I’m talking about intense, I have really good sleep weightlifting workouts. This one is not too hard to keep up with, considering it’s just 2-3 hours per week but I love the benefits.
            <ul>
                <li>Same thing as sugary breakfast, just don't buy it and problem solved.</li>
            </ul>
        </li>


    </ol>

            </div>
            <div className="contact-icons">
        <a href="https://twitter.com/Aryan_Gandhi101" target="_blank" rel="noreferrer">
          <FiTwitter size={32} />
        </a>
        <a href="mailto:aryang6@illinois.edu">
          <FiMail size={32} />
        </a>
      </div>
        </div>
    );
}

export default Article7;
