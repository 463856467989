import React from 'react';
import './Article.css';
import { FiTwitter, FiMail } from 'react-icons/fi';

function Article3() {
    return (
        <div className="article-container">
            <h1 className="article-title">🌎Create Your Own Beliefs</h1>
            <div className="article-content">
            <p>To pretty much everything, there are two sides, you can either agree or disagree.</p>
	
    <p>Now, some things are genuinely wrong, but you will still find a subset of people that argue in support of it. Crazy? I know. But that goes to show, this reality, righteousness, and making "correct" decisions is all your perception. What you see is atoms put together in some way, what you hear is a mix of sounds, however, the brain gives some type of meaning to all of this. At the end of the day, I think there's so much nonsense around us, that you need to decide on a set of beliefs that empower you.</p>
    <p>What do I mean?</p>

    <p>I'll give you a very common occurrence that I witness as people age. Science suggests that as you grow older, the neuroplasticity in your brain decreases, slowing your ability to learn. That's why people in their 30's, 40's and 50's can feel like they are behind a lot of the younger generation. But that advice is self-serving, and wearing that chip on your shoulder is going to inherently inhibit you from doing what you want. If you believe, whole heartedly, that you are an exception to the rule and you can learn the same rate, if not faster than people younger, you simply will. Sure, it might not be at the exact same rate, but there is a very good shot you will get damn near close. </p>

      <p> Something similar with fitness (they're younger they can do it, I'm old, my joints are this, etc..) </p> 

<p>I've noticed a few limiting beliefs that I hold, and I hope to remove them this upcoming summer. <br></br><p></p>  I'll start with one's from the past that I've overcame (for the most part): </p>
<ol>
                <li><b>I started programming late.</b> I heard stories of people programming since they were kids and grinding Leetcode for years, etc.. I started programming senior year of high school, toward the end of Covid, so for a long time I felt behind, especially from my peers. However, around a year of hard work and things are looking really well in computer science. This one is one I grasp to very loosely now, understanding I have a lot of time ahead of me.</li>
                <li><b>I don't have the "genetics".</b> This is one that I held for a good few years, through middle and most of high school. Big shocker here, I barely lifted and when I did it was mostly biceps. One good of year lifting and increased protein removed this from my mind completely.</li>
                <li><b>They're just smarter.</b> I'll take Elon Musk (love that guy) and a lot of other intelligent people who seem to be superhuman. I'll look at that and attribute a good amount to them having an amazingly high IQ, good luck, etc... But Steve Jobs' speech about everything around you was made by someone no smarter than you really changed the way I look at that.</li>
            </ol>
<p>And the current: </p>

<ol>

<li> <b>I have to "win the day". </b>I have a solid morning routine that I like to follow, but when I miss it for some reason and wake up late or deviate off track, I tend to consider the day as a loss, and it just spirals from there. 

</li>

<b>Alternate belief:</b> Split the day into 3 chunks, morning, afternoon and night, and viewing them as individual pieces to win. (I have an affinity to view events as winning or losing in terms of competing with myself.)  

<li> <b>You have to fit in, you never know when you'll meet these people again.</b> This was something my parents always told me, as they thought I was antisocial. For a period of time I believed it, and it really ate me to not say what was on my mind. Sometimes it would be because I was scared, or I didn't want to hurt someone's feelings, etc... But I realized if my intent was not to hurt someone's feelings, but I felt some type of way about a topic or situation, it's almost always better to say it. If I'm not speaking and thinking for myself, am I really living? </li>

<b>Alternate belief: </b>If the genuine intent isn’t to explicitly hurt someone, contemplate a decision and move forward with it Don’t overthink.
            
</ol>    

<p>Just come with a list of beliefs that support where you want your life to go, and follow those. You'll thank yourself down the line.</p>
            
            
            
            </div>
            <div className="contact-icons">
        <a href="https://twitter.com/Aryan_Gandhi101" target="_blank" rel="noreferrer">
          <FiTwitter size={32} />
        </a>
        <a href="mailto:aryang6@illinois.edu">
          <FiMail size={32} />
        </a>
      </div>
        </div>
    );
}

export default Article3;
