import React from 'react';
import './Article.css';

function Article9() {
    return (
<div className="article-container">
<h1 className="article-title">🤍Blessings</h1>
<div className="article-content">

<p>I tend to get caught up in what I'm doing, my goals, the gym, etc... However, right now I realize how lucky I am to have what I have and be where I am. If I ever get off track, I hope that I come back here and realize what I have.</p>

<h2> Parent blessings </h2>

 <ul>
                <li>Dad immigrated here, had to work for a company for 10+ years that ended up going bankrupt, couldn’t make career moves during the time due to Visa.</li>
                <li>Dad had to travel for his job every week for a couple years, made a 5+ hour drive twice weekly.</li>
                <li>Mom worked an extremely tedious typing job when first arrived at US.</li>
<li> Mom worked and made sure the house was in order always, for example, there was always a snack on the table when I came home from school.</li>
  </ul>

What’s crazy is that I don’t think I could do either of those. Maybe if shit hit the fan and I was forced too, then I could try, but going through all of that is insanely inspiring at the very least. Makes the stuff I complain about right now seem real small.

<h2> Friend blessings </h2>

<ul>

<li> I went into my thoughts on friends: <a href="https://www.aryangandhi.dev/writing/evaluatingfriendships">Friends</a>, but blessed to have a few friends in the last category. My life thus far would’ve been more difficult without support from good friends. </li>

</ul>

<h2> Health blessings </h2>

<ul>

<li>Someone living close by in Chicago is in 70’s, and has dementia. See him almost daily, each day re-introduces himself and who he is. That is one potential on how this life will end. His spouse told me they are considering moving him to a home. As soon as he retired, he was diagnosed with the disease. Makes me appreciate what I have a little more everyday.</li>

<li>Had someone close to me injure their back in a car crash. Couldn’t go to gym or lift heavy things for years, needed support and repeated checkups with a specialized doctor.</li>

<li>Don’t want to go into specifics, but amongst family and relatives, seen issues with cancer and other prominent diseases.</li>

</ul>

<p>These are just a few off the top of my head that I can think of. I want this essay to be more focused on the take away that these blessings have given me.</p>

<p>I know that at this point of my life, there are billions of people that would want to switch spots with me. Further, I am not saying this in an arrogant way. If you’re a 20 year old in a first world country with the above everyday blessings, there’s a good chance billions of people would swap spots with you. Of those older (time is the one thing you can’t reverse), those that are poor, those dealing with unstable governments, those with the other issues (significant diseases, chronic pain, extreme allergies, etc), most would switch spots with me. </p>

<p>I say that because it is a good reminder, a testament to what I can achieve. One of the biggest issues I see everywhere is lack of purpose. It’s really, really refreshing to meet someone that is very purposeful and actions and knows where they want to go. If I have more opportunity to do something than billions of people in the world, I should ensure that opportunity is not wasted. </p>

<p> I saw a video on Twitter a few weeks ago, where a random man approaches a teenager and asks the teenager to give him advice: </p>

<blockquote>

<p>Teenager: “What would you say if I gave you 10 million bucks”</p>

<p> Man: “Thank you … I’m not sure” </p>

<p> Teenager: “What if I attached the condition you will not wake up tomorrow” </p>

<p>Man: “I wouldn’t take it” </p>

<p> Teenager: “So you’re saying that tomorrow morning is worth more than 10 million dollars to you” </p>

<p> Man: “I guess I am, wow”</p>

<p> Teenager: “Remember that” </p>

</blockquote>

<p>

Every time I zoom out and count the blessings, it makes everything alright. This is written in a way that when I come back to it, I know why I need to do what I have to do. I will be at a cross roads shortly. Do I join a company, get my masters, join a startup, build my own company, take a year and explore? Who knows. But I want that decision to be guided by my blessings. </p>

<p> The point of this article is not some nihilistic view that count your blessings because at the end of the day nothing matters sort of way. Instead, I hope it serves as a reminder to me to act accordingly. Act with speed, precision and purpose in what I do. All these blessings have somehow lined up so that I can be where I am right now. An insane thought, that the hard work and luck of those before me allowed me to be here. It all consolidated to me sitting here in bed in Chicago writing this. </p>

<p> Don’t waste this blessing. Don’t waste it on dumb thoughts, don’t waste it on dumb worries and don’t waste it on dumb people. </p>

<p> It’s all a blessing. </p>

<p> ^^Got to keep reminding myself that. </p>

<h2> Footnote: </h2>

<p>General blessings: </p>


<ul classname="spacereduce">

<li> 4 limbs </li>

<li> Mental faculties intact </li>

<li>Food on table </li>

<li>Clean water </li>

<li>Parents together </li>

<li>Younger sibling (sometimes)</li>

<li>Money in bank account</li>

<li>Other family/friends/best friend (blessed with a few really good friends)</li>

<li>Allergies (just a couple)</li>

</ul>
</div>
</div>
    );
}

export default Article9;
