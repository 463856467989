import React from 'react';
import './Article.css';

function Article5() {
    return (
<div className="article-container">
<h1 className="article-title">🤝Evaluating Friendships</h1>
<div className="article-content">

<p>

About a week ago, I had a friend (let’s call them X) call me to catch up. I felt our friendship had been deteriorating for the last semester or so, and debated bringing it up with X. The call began casually for the first five minutes.</p>

<blockquote>
<p>X - “Oh you saw the game, the Celtics lost”</p>

<p>Me - “Yeah I saw the score”</p>

<p>X - “…” (something I don’t remember)</p>
</blockquote>

<p>And then I decided what’s the worst that could happen if I told X exactly how I felt. From the moments I thought were messed up to what used to go well, etc… I told X how I felt our friendship had been deteriorating. X then talked about how they recognized something similar, and we both discussed our friendship for a good hour. </p>

<p>The conversation ended, some resolutions and future steps were reached and we shall see if that works out. Now I know that I tried reaching out and having an open conversation. If things end up not working out down the line, it shows that this friendship was not meant to be. Better polarize now than later.</p>

<p>This conversation comes from a time when I’ve started to realize the importance of being around four types of people: </p>

  <ol>
            <li>Those that push you (competitively) → be around the most</li>
            <li>Those you can learn from (mentors) → not necessarily around that often, but available to help as long as you put in the effort </li>

<li> Those that are comfortable to be around (good friends) → hang with once in a while, good vibes </li>

<li> Those you trust (very, very good friends) → don’t need to hang often, but unspoken have each others back </li>

</ol>

<p>When thinking about where people fall in these categories (some could be multiple), anyone that does not fall in one of these categories (people that are consistently negative, people that make you feel bad/angry, people that never appreciate you, etc) I strongly encourage you to think about what these people even provide. If being around someone consistently makes you feel worse or incites anger, is their company truly worthwhile?</p>

<p>Peace of mind is a modern superpower; it can enhance your decision-making ability tenfold. The wrong people around you can easily be the biggest detriment to peace of mind. Do whatever it takes to protect it. </p>
            </div>
        </div>
    );
}

export default Article5;
