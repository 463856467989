import React from 'react';
import './Article.css';

function Article8() {
    return (
        <div className="article-container">
        <h1 className="article-title">🎵Meaningwave</h1>
        <div className="article-content">
        
                <p>Meaningwave by Akira the Don is my new favorite music style.</p>
                
                <p>He takes speeches from inspirations such as David Goggins, Naval Ravikant, and Marcus Aurelius and turns them into EDM songs.</p>
                
                <p>This is one of the most unique forms of music that I have seen, and I believe it will become popularized soon. </p>
                
                <p>What I like about it: </p>
                
                <ol>
                
                <li> Message. I don't usually listen to lyrics in a song, but listening to these provides a positive, inspirational message. In fact, I learn a good amount from some of these. </li>
                
                <li> Beat. The beat is awesome, I'm a big fan of beats in music and Akira hits it on the mark. </li>
                
                <li> Unique. I'm a big fan of unique music, and this genre definitely falls under that. </li>
                
                    </ol>
                
                <p>Biggest learnings:</p>
                
                <ol>
                <li>Become the best in the world in what you do. Further, what you do also need to be flexible. Imagine trying to become the best Python developer in the world. Hard? Beyond extremely. Even becoming best Python Django developer, or Python Pandas developer is extremely difficult and very unlikely to reach. The alternative is combining multiple things together that you enjoy and are good at to becoming the best in the world in that intersection. </li>
                
                <li> Taking souls. David finds ways and motivation to make things interesting. At the Hell Week, he realized these instructors are comparing themselves to the current trainees. Hence, while people were finding difficulty and pain in the extreme challenges, he and his crew were acting excited. This surprised the instructors, and that was what become their motivation, sticking it to the instructors. </li>
                
                <li> Systems. Settings goals and making plans is easy. What’s hard is setting up a system that you can consistently follow to meet the previous goals. A system that you enjoy on a daily basis is one of the best things you can do for productivity. That way, even on your worst days you fall to the level of the system, as opposed to self destructive behaviors. </li>
                

                </ol>

                <p> I’ve listened to his music for a day thus far, excited to listen to more. </p>
            </div>
        </div>
    );
}

export default Article8;
