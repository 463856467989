import React from 'react';
import './Article.css';
import { FiTwitter, FiMail } from 'react-icons/fi';
function Article2() {
    return (
        <div className="article-container">
<h1 className="article-title">💯Daily Wins Stack</h1>
<div className="article-content">
<p>If you win each day, you've won the year by a long shot. Here's a simple model to win the day.</p>

<p> Everyday we have plenty of decisions that we are faced with, what to eat, when to shower, what to do, who to meet, etc...</p>

<p> Inherently, you know what is the right and wrong decision to make. But due to comfort, laziness or some other option, one chooses the wrong decision.</p>

<p> Imagine the day as a chessboard, if you consistently make the "right moves" aka right decisions, you're not going to lose the game. Vice versa, if you play bad moves you’re going to lose pretty fast. It’s better to not move until you’re ready, than move hastily and blunder in chess. Further, even if you plan amazing moves at the start and have a small blunder later in the game, there's a good chance you lost the game. That’s why the most crucial thing in chess is to play the correct move, and do so swiftly (in chess you have a timer). But the swiftly part is secondary, most important is playing the correct move (as that is how you can lose the fastest). If you keep making the correct moves with a fair bit of speed, the time will take care of itself.</p>

<p>View each day as a chessboard, and your opponent is a really solid player (2000 ELO). Your goal is to make each move (decision) correctly in the day in order to avoid getting your pieces taken. </p>

<p>

After multiple games of playing correctly, there will be a day when this 2000 ELO master makes a mistake, and you will be able to capitalize and win the game. Similarly, keep making the correct decisions about your day, and you will be able to capitalize when the opportunity presents itself. </p>

<p>Viewing each day as a separate entity/game allows for you to hit those goals without feeling overwhelmed or confused on your vision. You know what you have to do, you're going to make the right moves, and within a few weeks, months or years of making the right moves, you'll have an opportunity to win. </p>
</div>

    </div>
    );
}

export default Article2;
