import React from 'react';
import './Article.css';
import { FiTwitter, FiMail } from 'react-icons/fi';

function Article4() {
    return (
        <div className="article-container">
        <h1 className="article-title">🤔The 180 Day Challenge</h1>
        <div className="article-content">
        

        <p>Over the next 180 days I will publish 100 essays, through writing 30 minutes every day.</p>
        
        
        
        <p><b>How is my writing different?</b></p>
        
        <p>Most of us are guilty of taking what we hear online and putting into own words without giving much thought. Hence, it depends on the quality of the people that you listen to, how your writing and thoughts will turn out.</p>
        
        <p>While I strongly believe that the people I follow have made me a much better person intellectually (check out inspiration: <a href="https://www.aryangandhi.dev/">Home</a>). 
 I want to write these essays from my own point of view.</p>
        
        <p>While some ideas may stem from other individuals, I will only write essays on ideas I have tried and have a personal experience with (which I will mention in the essay). This applies to essays that are about development, and similar fields. There will be essays where I analyze certain topics that I want to become more knowlegeable on (sleep, learning, etc..) and those may have personal experiences but are more focused on research.</p>
        
        <p><b>Why am I doing this?</b></p>
        
        <p>Writing has always cleared my mind and put my beliefs into perspective. I lost this art over the past couple years and decided what better way to get back in then this challenge.</p>
        
        <p>Also, I hope this writing allows me to meet some pretty cool people on the internet.</p>
        
        <p>Join me on this journey, and feel free to give me feedback and chat it up. </p>
        
       
                </div>
            </div>
        );
        }

export default Article4;
