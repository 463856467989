import React from 'react';
import './Article.css';
import { FiTwitter, FiMail } from 'react-icons/fi';

function Article1() {
    return (
        <div className="article-container">
            <h1 className="article-title">📚Building a Community: Summer 2023</h1>
            <div className="article-content">
                <p>Arguably one of my favorite times in college so far was building UIUC NutriScrape, a platform that scraped UIUC’s dining hall nutrition information daily and outputted meals based on a students nutrition goals.</p> 

                <p>For 4 months, a friend and I spent around 200 hours building this platform, and those hours were extremely blissful, no distractions just finishing task after task to build the platform. This project came to abrupt halt after we made way too may api calls to the university (they changed their website urls). I’ve built a few more things solo since then, but building with someone is a lot more fun and builds a friendship. I want to curate a community this summer to just that, build a lot of cool things and become close. There post will cover what I need to do and I’ll update it periodically as I build this group.</p> 
                
                <ol>
                    <li>Currently I’ve met the most builders through Twitter, I want to grow my Twitter following to 1000+ by end of the summer. Not for the followers but for the opportunities this opens up. How? Going to write about the strategy soon.</li>
                    <li>Setting up a group to attend tech events to in Chicago, right now there are ~10 people, however if you are reading this feel free to let me know via <a href="https://twitter.com/Aryan_Gandhi101">Twitter</a> or email if you want to be added</li>
                    <li>Working on a platform with Paul Clancy, co-author of Bread Crumbs. Not sure where this is headed but I will update this if more happens.</li>
                </ol>
            </div>
            <div className="contact-icons">
        <a href="https://twitter.com/Aryan_Gandhi101" target="_blank" rel="noreferrer">
          <FiTwitter size={32} />
        </a>
        <a href="mailto:aryang6@illinois.edu">
          <FiMail size={32} />
        </a>
      </div>
        </div>
        
    );
}

export default Article1;
