import { BrowserRouter as Router, Route, Routes, Outlet, Link, useNavigate } from 'react-router-dom';
import Projects from './Projects';
import Ideas from './Ideas';
import Experience from './Experience';
import Writing from './writing';
import Article1 from './articles/Article1';
import Article2 from './articles/Article2';
import Article3 from './articles/Article3';
import Article4 from './articles/Article4';
import Article5 from './articles/Article5';
import Article6 from './articles/Article6';
import Article7 from './articles/Article7';
import Article8 from './articles/Article8';
import Article9 from './articles/Article9';
import Article10 from './articles/Article10';
import Article11 from './articles/Article11';
import Article12 from './articles/Article12';
import Article13 from './articles/Article13';
import Article14 from './articles/Article14';
import Article15 from './articles/Article15';
import Article16 from './articles/Article16';
import Article17 from './articles/Article17';
import Article18 from './articles/Article18';
import Article19 from './articles/Article19';
import Article20 from './articles/Article20';
import { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import './App.css';



Modal.setAppElement('#root');

const ModalSelector = ({commands, command, setCommand}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleChange = (command) => {
    if(commands[command]) commands[command]();
    setCommand(command);
    closeModal();
  };

  return (
    <>
      <button onClick={openModal}>Select command</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Command Selector"
      >
        <h2>Select command</h2>
        {Object.keys(commands).map(cmd => (
          <button key={cmd} onClick={() => handleChange(cmd)}>
            {cmd}
          </button>
        ))}
      </Modal>
    </>
  );
};


const App = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Listener for window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <Routes>
  <Route path="/" element={<Main isMobile={isMobile} />} />
  <Route path="/projects" element={<Projects />} />
  <Route path="/ideas" element={<Ideas />} />
  <Route path="/experience" element={<Experience />} />
  <Route path="/writing" element={<Writing />} />
  
  <Route path="/writing/buildcommunity" element={<Article1 />} />
  <Route path="/writing/dailywins" element={<Article2 />} />
  <Route path="/writing/createbeliefs" element={<Article3 />} />
  <Route path="/writing/thechallenge" element={<Article4 />} />
  <Route path="/writing/evaluatingfriendships" element={<Article5 />} />
  <Route path="/writing/naval" element={<Article6 />} />
  <Route path="/writing/lousyday" element={<Article7 />} />
  <Route path="/writing/meaningwave" element={<Article8 />} />
  <Route path="/writing/blessings" element={<Article9 />} />
  <Route path="/writing/socialmedia" element={<Article10 />} />

  <Route path="/writing/informationoverload" element={<Article11 />} />
  {/* <Route path="/writing/dailywins" element={<Article2 />} />
  <Route path="/writing/createbeliefs" element={<Article3 />} />
  <Route path="/writing/thechallenge" element={<Article4 />} />
  <Route path="/writing/evaluatingfriendships" element={<Article5 />} />
  <Route path="/writing/naval" element={<Article6 />} />
  <Route path="/writing/lousyday" element={<Article7 />} />
  <Route path="/writing/meaningwave" element={<Article8 />} />
  <Route path="/writing/blessings" element={<Article9 />} />
  <Route path="/writing/socialmedia" element={<Article10 />} /> */}

  


</Routes>

    </Router>
  );
};

const Main = ({isMobile}) => {
  const navigate = useNavigate();
  const [command, updateCommand] = useState('');
  const [output, updateOutput] = useState([]);
  const [lastLogin, setLastLogin] = useState('');
  const [dropdownCommand, setDropdownCommand] = useState('');

  const [isWelcomeButtonClicked, setIsWelcomeButtonClicked] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  const inputRef = useRef(null);

  const handleRedirect = (url) => window.location.href = url;

  const commands = {
    'clear': () => {
      updateOutput([]);
   },
   'cd clear': () => {
    updateOutput([]);
 },
    'about': () => {
      var apostrophe = '\u0027';
      const aboutMessage = 'Hi👋, my name is Aryan Gandhi! \n I' + apostrophe + 'm a Computer Science and Statistics student at the University of Illinois. Love to build, lift and eat chipotle. \nI' + apostrophe +'ve met some pretty cool people through the internet, so if you' + apostrophe +'re here, reach out on any of the social media listed!';
      
      updateOutput([...output, { command: 'about', message: aboutMessage }]);
      setDropdownCommand('');
    },
    'cd about': () => {
      var apostrophe = '\u0027';
      const aboutMessage = 'Hi👋, my name is Aryan Gandhi! \n I' + apostrophe + 'm a Computer Science and Statistics student at the University of Illinois. Love to build, lift and eat chipotle. \nI' + apostrophe +'ve met some pretty cool people through the internet, so if you' + apostrophe +'re here, reach out on any of the social media listed!';
      
      updateOutput([...output, { command: 'cd about', message: aboutMessage }]);
      setDropdownCommand('');
    },

    'email': () => handleRedirect("mailto:aryang6@illinois.edu?subject=Saying Hello!&body=Sup! my name is "),
    'cd email': () => handleRedirect("mailto:aryang6@illinois.edu?subject=Saying Hello!&body=Sup! my name is "),
    'experience': () => navigate('/experience'),
    'cd experience': () => navigate('/experience'),

    "github": () => handleRedirect('https://github.com/aryandgandhi'),
    "cd github": () => handleRedirect('https://github.com/aryandgandhi'),
    'ideas': () => navigate('/ideas'),
    'cd ideas': () => navigate('/ideas'),
        
    'inspiration': () => {
      const quoteMessage = `Health + Productivity + Mindset: \nNaval Ravikant, Andrew Huberman, Sam Altman, Paul Graham, Alex Hormozi, Tom Bileau, David Goggins, Ryan Holiday, Patrick Bet-David \n Building + Business + Startups: \nNaval Ravikant, Sam Altman, Paul Graham, Alex Hormozi, Jessica Livingston, Sheryl Sandberg, Shaan Puri, Ray Dalio`;

      updateOutput([...output, { command: 'inspiration', message: quoteMessage }]);
      setDropdownCommand('');
    },
    'cd inspiration': () => {
      const quoteMessage = `Health + Productivity + Mindset: \nNaval Ravikant, Andrew Huberman, Sam Altman, Paul Graham, Alex Hormozi, Tom Bileau, David Goggins, Ryan Holiday, Patrick Bet-David \n Building + Business + Startups: \nNaval Ravikant, Sam Altman, Paul Graham, Alex Hormozi, Jessica Livingston, Sheryl Sandberg, Shaan Puri, Ray Dalio`;

      updateOutput([...output, { command: 'cd inspiration', message: quoteMessage }]);
      setDropdownCommand('');
    },

    "linkedin": () => handleRedirect("https://www.linkedin.com/in/aryan-gandhi-101/"),
    "cd linkedin": () => handleRedirect("https://www.linkedin.com/in/aryan-gandhi-101/"),


    'projects': () => {
      const workingOnMessage = "Azidity -> Developing a full stack platform to connect students to mentors (on pause) \n Easysource -> Lowering the barrier of entry to open source using AI \nDataSherlock -> Allowing in-browser data analysis fast for those that want to explore their data (more for students)\nSheetTalk ->  Keep track of your personal relationships in this CRM";

      updateOutput([...output, { command: 'projects', message: workingOnMessage }]);
      setDropdownCommand('');
    }, 

    'cd projects': () => {
      const workingOnMessage = "Azidity -> Developing a full stack platform to connect students to mentors (on pause) \n Easysource -> Lowering the barrier of entry to open source using AI \nDataSherlock -> Allowing in-browser data analysis fast for those that want to explore their data (more for students)\nSheetTalk ->  Keep track of your personal relationships in this CRM";

      updateOutput([...output, { command: 'cd projects', message: workingOnMessage }]);
      setDropdownCommand('');
    }, 
    "twitter": () => handleRedirect('https://twitter.com/Aryan_Gandhi101'),
    "cd twitter": () => handleRedirect('https://twitter.com/Aryan_Gandhi101'),
    'writing': () => navigate('/writing'),
    'cd writing': () => navigate('/writing'),
    'leetcode': () => navigate(''), 
    'cd leetcode': () => navigate('/leetcode')
    
  
    

  };

  const mobileCommands = {
    'clear': () => {
      updateOutput([]);
   },
    'about': () => {
      var apostrophe = '\u0027';
      const aboutMessage = 'Hi👋, my name is Aryan Gandhi! \n I' + apostrophe + 'm a Computer Science and Statistics student at the University of Illinois. Love to build, lift and eat chipotle. \nI' + apostrophe +'ve met some pretty cool people through the internet, so if you' + apostrophe +'re here, reach out on any of the social media listed!';
      
      updateOutput([...output, { command: 'about', message: aboutMessage }]);
      setDropdownCommand('');
    },

    'email': () => handleRedirect("mailto:aryang6@illinois.edu?subject=Saying Hello!&body=Sup! my name is "),

    'experience': () => navigate('/experience'),

    "github": () => handleRedirect('https://github.com/aryandgandhi'),
  
    'ideas': () => navigate('/ideas'),

        
    'inspiration': () => {
      const quoteMessage = `Health + Productivity + Mindset: \nNaval Ravikant, Andrew Huberman, Sam Altman, Paul Graham, Alex Hormozi, Tom Bileau, David Goggins, Ryan Holiday, Patrick Bet-David \n Building + Business + Startups: \nNaval Ravikant, Sam Altman, Paul Graham, Alex Hormozi, Jessica Livingston, Sheryl Sandberg, Shaan Puri, Ray Dalio`;

      updateOutput([...output, { command: 'inspiration', message: quoteMessage }]);
      setDropdownCommand('');
    },
  

    "linkedin": () => handleRedirect("https://www.linkedin.com/in/aryan-gandhi-101/"),



    'projects': () => {
      const workingOnMessage = "Azidity -> Developing a full stack platform to connect students to mentors (on pause) \n Easysource -> Lowering the barrier of entry to open source using AI \nDataSherlock -> Allowing in-browser data analysis fast for those that want to explore their data (more for students)\nSheetTalk ->  Keep track of your personal relationships in this CRM";

      updateOutput([...output, { command: 'projects', message: workingOnMessage }]);
      setDropdownCommand('');
    }, 

 
    "twitter": () => handleRedirect('https://twitter.com/Aryan_Gandhi101'),

    'writing': () => navigate('/writing'),

    
  };

  useEffect(() => {
    const now = new Date();
    setLastLogin(now.toLocaleString());

    if (isWelcomeButtonClicked || !isIOS) {
      inputRef.current.focus();
    }
  }, [isWelcomeButtonClicked, isIOS]);

  const handleKeyDown = async (e) => {
    const isControlKey = ['Backspace', 'Tab', 'Shift', 'Control', 'Alt', 'CapsLock', 'Meta'].includes(e.key);

    if (e.key === 'Enter') {
      e.preventDefault();
      if (commands[command]) commands[command]();
    
      else if (command === "cd") {
        const helpMessage = `\nType a command: about, email, experience, github, ideas, inspiration, linkedin, projects, twitter, writing
      `;
      updateOutput([...output, { command, message: helpMessage }]);
      } else if (commands[command]) {
        commands[command]();
      }
      else updateOutput([...output, { command, message: 'zsh: command not found: ' + command +'. Checkout "writing."' }]);
      
      updateCommand('');
    } else if (!isControlKey && !e.key.startsWith('Arrow')) {
      updateCommand((prevCommand) => prevCommand + e.key);
    }
  };
  
  const handleKeyUp = (e) => {
    if (e.key === 'Backspace') {
      updateCommand((prevCommand) => prevCommand.slice(0, prevCommand.length - 1));
    }
  };

  const handleFocus = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <div className="App" onClick={handleFocus} style={{ textAlign: "left" }}>
     {isMobile && <ModalSelector commands={isMobile ? mobileCommands : commands} 
      command={dropdownCommand} 
      setCommand={setDropdownCommand} />}
     {isMobile && <p>Check it out on a laptop, it's more fun there :/ If you insist on mobile, click the command box above</p>}
      <p>Last login: {lastLogin}</p>
      <h1>Aryan Gandhi's Website</h1>
      <p>Type <code>cd</code> for a list of commands </p>
      <p>Type <code>clear</code> to clear terminal </p>
      <p className="dashes">--------------------------------------------</p>

      <div 
        className="command-output" 
        tabIndex="0" 
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        ref={inputRef}
      >

<input
    className="invisible-input"
    ref={inputRef}
    onKeyDown={handleKeyDown}
    onKeyUp={handleKeyUp}
    onClick={() => inputRef.current.focus()}
    autoComplete="off"
    autoCorrect="off"
    autoCapitalize="off"
    spellCheck="false"
/>
      
{output.map((item, index) => (
  <div key={index}>
    <span className="prompt">guest@Aryans-MacBook-Pro-8 ~ % </span>
    <span className="command command-entered">{item.command}</span>
    <div> 
      {typeof item.message === 'string' ? (
        item.message.split('\n').map((line, lineIndex) => (
          <div key={lineIndex}><span className="message">{line}</span></div>
        ))
      ) : (
        <div dangerouslySetInnerHTML={{__html: item.message}} />
      )}
    </div>
  </div>
))}
        <div className="command-line">
          <span className="prompt">guest@Aryans-MacBook-Pro-8 ~ % </span>
          <span className="command">{command}</span><span className="cursor"></span>
        </div>
      </div>
    </div>
  );
};

export default App;

