import React from 'react';
import './Article.css';
import airchatImg from './airchat.png'; // adjust the path as neede


function Article6() { 
    return (
        <div className="article-container">
        <h1 className="article-title">🌱Naval</h1>
        <div className="article-content">
        

        <p>Around 2.5 years ago, my family and I were headed to a tropical state for vacation. I had just gotten into UIUC for business, but was having second thoughts. In all honesty, I wasn't even sure what use a business degree would be.</p>
        
        
        
        <p>Furthermore, I had recently split with an ex of ~2 years, so all of that was on my mind. For about two weeks I contemplated what I should do, what changes to make, etc... Nonetheless, I remained confused and nervous, and made no progress.</p>
        
        <p>By some stroke of luck, I'm looking at book recommendations on Reddit, and the Navalmanac was listed as one of them. I start reading a pdf of it online, and I would argue those few minutes were the most valuable of my life.</p>
        
        <p>We reach the hotel and I ask the receptionist if she could print a few pages from the book.  She prints about 50 pages of it and on our flight I remain engrossed in what he talks about.</p>
        
        
        
        
        <h3>I can't remember exactly what stood out to me, but here is my best attempt at recollection:</h3>
        
                    <ol>
             <li><b>Arm yourself with specific knowledge, accountability and leverage.</b>
        At the time I didn't care about a personal brand and didn't understand how to build wealth. This opened up my eyes to what sort of content to consume, and how to think long term in these areas.</li>
        <li><b>Code and media are permissionless leverage.</b> A big reason I learned to code and write has to do with them being permissionless leverage. They allow me to create whatever I want, attracting similar people to me. </li>
        <li><b>Eliminate options.</b>There's so much one can do, but there is very few things that one should do. I started to realize that if I was forcing a yes to do something, I might as well say no. Then, you start freeing up a lot of time to do what you want. </li>
        <li><b>Happiness is a choice.</b> This is the most profound realization on this list, one that I am still working on internalizing. I'd rather you hear exactly what he means, so check it out here: <a href = "https://www.navalmanack.com/almanack-of-naval-ravikant/happiness-is-a-choice">Naval Happiness</a>.</li>
                    </ol>
        
             
        
        <p>As I read more about him, listened to podcasts with him, etc... I knew that one day I wanted to meet him.</p>
        <p>Yesterday I got one step closer to that. I was accepted to Airchat, and I posted a tweet about it, which he liked. While a small milestone, it means someone who I look up to has read something that I wrote. </p>
        <img className = "image-container" src={airchatImg} alt="Airchat"></img>
     
        <p> I wrote this piece to put people on Naval and his work. If there was any one book I could give to everyone, it would be the Navalmanac. It sounds like I idolize this guy, but out of every person I've ever listened or talked to, I would say he is the closest thing to the ideal role model (for me). </p>
        <p> I debated whether I was saying all of this because he helped pull me out of a hard time. The main goal of writing is to do my best to remain objective. However, I can confidently say anytime I read his content, I'm inspired and learn something new. If you haven't read or listened to him, you're missing out significantly. </p>
                  
                </div>
            </div>
    
    );
}

export default Article6;
