import React from 'react';
import './ideas.css';

const Ideas = () => {
  return (
    <div className="ideas-container">
      <h2 className="ideas-header">Ideas</h2>
      <div className="idea-content">
        <p>Idea 1: Create a personal project database for developers.</p>
      </div>
      <div className="idea-content">
        <p>Idea 2: Start the collaboration project, the premise is a lot of code especially for students during school is forgetten about. Help people collaborate and turn
          this code into something.
        </p>
      </div>
      <div className="idea-content">
        <p>Idea 3: Create a way to interact with pandas using an llm as it is pretty good at text generation (currently contributing open-source)</p>
      </div>
      <div className="idea-content">
        <p>Idea 4: Users can upload dataset, option to run specific models with specifiic parameters (regression, random forest, etc)</p>
      </div>
      <div className="idea-content">
        <p>Idea 5: Develop teleportation</p>
      </div>
      <div className="idea-content">
        <p>Idea 6: Personal database, updated using sheets API and either bard or openAI API. Use Twilio or another number provider for texting updates, that are then passed through llm</p>
      </div>
      <div className="idea-content">
        <p>Idea 7: Protein Celsius</p>
      </div>
      <div className="idea-content">
        <p>Idea 8: Live gym app to make help friends, courtesy to not disturb during lift but if people want to make friends can use</p>
      </div>
    </div>
  );
};

export default Ideas;